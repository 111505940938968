import { Box, Button, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { generateClient } from 'aws-amplify/api'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppContext } from '../App'
import AppointmentsSection from '../components/AppointmentsSection'
import ChatMessages, { fetchCase } from '../components/ChatMessages'
import NewAppointmentDialog from '../components/NewAppointmentDialog'
import NotesSection from '../components/NotesSection'
import { fetchPatientsDetails } from '../components/PatientPanel'
import ShowErrorModal from '../components/ShowErrorModal'
import { getPatientsAppointments } from '../graphql-queries/customQueriesGraphQL'
import { Appointment, GraphQlResponseList } from '../graphql-queries/types'
import { postSingleAppointment } from '../postSingleAppointment'
import { appointmentRegistrationValidation } from '../utils/appointmentRegistrationValidation'
import { generateSortKey } from '../utils/sortKeyHelpers'
import renameAppointments from '../renameAppointments'

export const fetchPatientsAppointments = async (
  partitionKey: string,
  sortKey: string,
) => {
  const client = generateClient()

  const response = await client.graphql({
    query: getPatientsAppointments,
    variables: {
      limit: 1000000,
      filter: {
        and: [
          { PK: { eq: partitionKey } },
          { SK: { beginsWith: generateSortKey('CASE', sortKey, 'APP') } },
          { SK: { notContains: 'CHAT' } },
          { SK: { notContains: 'INST' } },
        ],
      },
    },
  })
  console.log('AAAAAAA Appointments', response)
  return (response as GraphQlResponseList<Appointment>).data.listMains.items
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$ PatientsChatPage $$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */
const PatientsChatPage = () => {
  const { user, instructionID } = useContext(AppContext)
  console.log('ßßßßßßßßß in PatientsChatPage instructionID', instructionID)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [appointment, setAppointment] = useState({
    name: '',
    date: '',
    instructionID: '',
  })
  const navigate = useNavigate()
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorDialogTitle, setErrorDialogTitle] = useState('')
  const [errorInstruction, setErrorInstruction] = useState('')
  const [submitFlag, setSubmitFlag] = useState(false)
  const { partitionKey, sortKey } = useParams()
  //Note: context may be cleared by page reload; !patientName check exists to handle this case
  const {
    setPageTitle,
    setIntervention,
    setPatientName,
    intervention,
    patientName,
  } = useContext(AppContext)

  if (!patientName) {
    fetchPatientsDetails(partitionKey ?? 'missing partition key').then(
      (patient) => setPatientName(patient.PatientName),
    )
  }

  useEffect(() => {
    if (submitFlag) {
      const validationResult = appointmentRegistrationValidation({
        appointment,
      })
      if (validationResult.isValid) {
        submitToDatabase(appointment)
      } else {
        const firstErrorKey = Object.keys(validationResult.errors)[0]
        showError(
          new Error(validationResult.errors[firstErrorKey]),
          'Validierungsfehler',
          'Vervollständigen Sie bitte die Eingabefelder. Folgende Unstimmigkeit wurde festgestellt: ',
        )
      }
      setSubmitFlag(false) // Reset the flag after processing
    }
  }, [submitFlag, appointment])

  if (!sortKey) {
    throw new Error('Sort Key is missing in URL!')
  }

  if (!partitionKey) {
    throw new Error('Partition Key is missing in URL!')
  }

  const showError = (error: Error, title = 'Error', instruction: string) => {
    const errorMessage = error?.message || 'An unknown error occurred'
    setErrorMessage(errorMessage)
    setErrorDialogTitle(title) // Use the passed title or a default value
    setErrorInstruction(instruction) // Use the passed title or a default value
    setErrorModalOpen(true)
  }

  const {
    data: appointments,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['patients', partitionKey, 'cases', sortKey, 'appointments'],
    queryFn: () => fetchPatientsAppointments(partitionKey, sortKey),
  })

  const handleTitleChange = (newTitle: string) => {
    setPageTitle(newTitle)
  }

  const latestAppointment = appointments
    ?.filter((appointment) => {
      // Assuming 'Date' is a string that represents a date
      const appointmentDate = new Date(appointment.Date)
      const now = new Date()
      return appointmentDate < now // Keeps only past appointments.
    })
    .sort((a, b) => {
      const dateA = new Date(a.Date).getTime()
      const dateB = new Date(b.Date).getTime()
      return dateB - dateA // Descending order
    })
    .map((appointment) => appointment.SK) // Extract the SK field from the Appointment
    .at(0) // Gets the SK of the latest past appointment.

  const { data: patientInfo, refetch: refetchPatientInfo } = useQuery({
    queryKey: ['patients', partitionKey, 'details'],
    queryFn: () =>
      fetchPatientsDetails(partitionKey ?? 'missing partition key'),
  })
  console.log('CCCCCCCCCCC patientInfo', patientInfo)

  const { data: caseDetails } = useQuery({
    queryKey: ['caseDetails', partitionKey, 'cases', sortKey],
    queryFn: () => fetchCase(partitionKey, sortKey),
  })
  console.log('CCCCCCCCCCC caseDetails', caseDetails)

  React.useEffect(() => {
    // Set intervention if caseDetails is defined
    let myIntervention = ''
    if (caseDetails?.Intervention) {
      setIntervention(caseDetails.Intervention)
      myIntervention = caseDetails.Intervention
    }
    // My intention was to use intervention from the AppContext as set above with setIntervention
    // But this did not work since the setIntervention is asynchronous and when I used the
    // context value in the line below, it was still holding no value or the value of the
    // previous rendering. So, as interim solution, I am using myIntervention
    handleTitleChange(
      `Patienten >> ${patientName || 'Default Patient Name'} >> ${myIntervention}`,
    )
  }, [caseDetails])

  const submitToDatabase = async (appointment: {
    name: string
    date: string
  }) => {
    console.log('DDDDDDDDDDD in submitToDatabase appointment:', appointment)
    console.log('DDDDDDDDDDD in submitToDatabase sortKey:', sortKey)
    const strippedSK = `CASE#${sortKey}#APP#`
    try {
      const singleAppointmentData = {
        pk: patientInfo?.PK,
        activeCase: sortKey,
        appointmentNumber: String(Number(patientInfo?.CounterAppointment) + 1),
        relevantClinic: patientInfo?.ClinicAccess,
        appointment: appointment,
        userIdDefined: user?.PK,
      }
      console.log('DDDDDDDDD singleAppointmentData:', singleAppointmentData)

      await postSingleAppointment(singleAppointmentData)
      // Depending on the user's decision about the new appointment's date, the
      // proposed name for the new appointment might not be correct. Hence the names
      // of all appointments have to be adjusted.
      await renameAppointments(patientInfo?.PK || '', strippedSK)
      await refetch()
      await refetchPatientInfo()
      // Handle successful submission, e.g., clearing the form, showing a message, etc.
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error('There was an error posting the appointment', error)
      throw error
    }
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleNewAppointmentSubmit = (
    date: string,
    appointmentName: string,
  ) => {
    // Here you would handle the logic to add the new appointment date to your database
    console.log(
      'DDDDDDDDDDD New appointment date, appointmentname:',
      date,
      appointmentName,
    )
    setAppointment({
      name: appointmentName,
      date: date,
      instructionID: instructionID,
    })
    setSubmitFlag(true)

    const appointmentData = {
      appointment: appointment,
    }

    console.log('DDDDDDDDDDD appointmentData:', appointmentData)

    // Validate the appointment data
    const validationResult = appointmentRegistrationValidation(appointmentData)

    if (validationResult.isValid) {
      // If validation passes, submit the data
      try {
        //            await postCase(caseData);
        // Handle successful submission, e.g., clearing the form, showing a message, etc.
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('There was an error posting the case data', error)
        throw error
      }
    } else {
    }
    // Close the dialog
    handleCloseDialog()
    // Here you might want to refetch or update the case data to reflect the new appointment
  }

  const handleErrorClose = () => {
    setErrorModalOpen(false)
  }

  const handleAddAppointment = () => {
    if (appointments) {
      setIsDialogOpen(true)
    }
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      ></Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={8}
        >
          {latestAppointment && (
            <ChatMessages latestAppointment={latestAppointment} />
          )}
        </Grid>
        <Grid
          item
          xs={4}
        >
          {appointments && (
            <NewAppointmentDialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              handleSubmit={handleNewAppointmentSubmit}
              nextAppointmentName={'Termin ' + String(appointments.length + 1)}
            />
          )}
          <Box
            flexDirection={'column'}
            alignItems={'center'}
          >
            {appointments && (
              <AppointmentsSection
                appointments={appointments}
                isLoading={isLoading}
                isError={isError}
                patientName={patientInfo?.PatientName || 'Default Patient Name'}
                onAddAppointment={handleAddAppointment} // pass the handler function here
              />
            )}
            <NotesSection activeCase={sortKey} />
          </Box>
        </Grid>
      </Grid>
      <ShowErrorModal
        open={errorModalOpen}
        handleClose={handleErrorClose}
        errorMessage={errorMessage}
        dialogTitle={errorDialogTitle}
        errorInstruction={errorInstruction}
      />
    </>
  )
}

export default PatientsChatPage

